<!-- 生成海报 中等版-->
<template>
  <div class="wrap">
    <div v-if="isShow">
      <div class="smk-select">
        <span>选择分享职位：</span>

        <el-select v-model="value" vplaceholder="请选择">
          <el-option
            v-for="item in show"
            :key="item.id"
            :label="item.jd_title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div
        class="img_wrap"
        @click="openDialog(index)"
        v-for="(item, index) in posterList"
        :key="index"
      >
        <img v-lazy="item.file_id" alt :key="item.id" />

        <!-- <img :src="item.file_id" alt /> -->
        <div class="title_hint" v-if="item.file_id">
          <span>点击即可分享海报</span>
        </div>
      </div>
    </div>
    <p class="kong" v-else>暂无海报</p>
    <!-- <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="100"></el-pagination>
    </div>-->
    <el-dialog
      title="点击鼠标右键即可保存图片"
      :visible.sync="dialogVisible"
      width="900px"
      center
    >
      <canvas ref="poster" class="poster"></canvas>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState, mapActions } from "vuex";
import { getImg } from "@/api/promotion";
import { getCode } from "@/api/tool";
import Vue from "vue";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  preLoad: 0.8,
  // error: 'dist/error.png',
  loading: require("./../../assets/images/test/isdown.gif"),
  attempt: 1,
});
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //海报数据
      posterList: [
        // { url: require('./../../assets/images/test/poster25.jpg') },
        // { url: require('./../../assets/images/test/poster28.jpg') },
        // { url: require('./../../assets/images/test/poster25.jpg') },
        // { url: require('./../../assets/images/test/poster28.jpg') },
        // { url: require('./../../assets/images/test/poster25.jpg') },
        // { url: require('./../../assets/images/test/poster28.jpg') },
        // { url: require('./../../assets/images/test/poster25.jpg') },
        // { url: require('./../../assets/images/test/poster28.jpg') },
        // { url: require('./../../assets/images/test/poster25.jpg') }
      ],
      dialogVisible: false,
      value: "",
      ecode: "",
      isShow: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      show: (state) => state.tool.show,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapActions({
      getJdData: "tool/getJD",
    }),

    //点击分享海报
    openDialog(index) {
      if (this.value === "") {
        this.$message({
          message: "请先选择职位",
          type: "warning",
        });
        return;
      }

      this.dialogVisible = true;

      var _index = index;
      this.$nextTick(() => {
        this.createdImg(_index);
      });
    },
    //canvas
    createdImg(index) {
      var arr = [];

      //获取职位jd——id
      var jd_id = "";
      console.log("***************");
      for (let i = 0; i < this.show.length; i++) {
        const element = this.show[i];

        if (this.value == element.id) {
          jd_id = element.jd_id;
        }
      }

      console.log(jd_id);

      //生成职位id对应的小程序码
      console.log("------------------");
      console.log("jd_id =" + jd_id);
      getCode({ is_refresh: "yes", jd_id: jd_id }).then((res) => {
        console.log("res.data.company_qr_a = ", res.data.company_qr_a);
        this.ecode = res.data.company_qr_a;

        console.log(this.ecode);

        arr.push(this.loadImg(this.posterList[index].file_id));
        arr.push(this.loadImg(this.ecode));

        // arr.push(this.loadImg(this.posterList[1].url))
        let logo = sessionStorage.getItem("logo");
        arr.push(this.loadImg(logo));

        var that = this;
        Promise.all(arr).then(function (arr) {
          console.log(arr, 1);
          that.draw(arr);
        });

        console.log("this.ecode 111111111111111");
        console.log(arr);
      });
    },
    //promise
    loadImg(src) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.src = src;
        img.onload = function () {
          //加载成功
          resolve(img);
        };
        img.onerror = function () {
          //加载失败
          reject("加载错误");
        };
      });
    },
    //绘制canvas
    draw(arr) {
      let data = {};
      this.show.filter((item) => {
        if (item.id === this.value) {
          data = item;
        }
      });

      console.log(data);
      let salary = data.jd_salary_min + "-" + data.jd_salary_max + "元";
      var canvas = this.$refs.poster;

      canvas.width = 800;
      canvas.height = 1360;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(arr[0], 0, 0, 800, 1360); //bc
      ctx.drawImage(arr[1], 550, 940, 210, 210); //erweima
      ctx.drawImage(arr[2], 46, 840, 100, 100); //logo

      console.log("图片上的二维码=", arr[1]);

      ctx.font = "30px '微软雅黑'";

      ctx.fillText(data.company_name, 160, 900); //公司名称
      ctx.fillStyle = "red";

      ctx.fillText(salary, 79, 987); //薪资

      ctx.font = "24px '微软雅黑'";
      ctx.fillStyle = "#000";
      ctx.fillText(data.jd_title, 223, 1054); //职位名称
      // ctx.fillText('list.company_name', 110, 456) //公司名称
      ctx.fillText(data.jd_exp_name, 223, 1096); //工作经验
      ctx.fillText(data.jd_edu_name, 223, 1142); //学历要求
      ctx.fillText(data.place_name, 223, 1185); //工作地点
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getJdData();
    console.log("ffffffffffff");

    getCode({ is_refresh: "yes" }).then((res) => {
      this.ecode = res.data.company_qr_a;
    });
    getImg().then((res) => {
      this.posterList = res.data.poster_list;
      this.posterList.length > 0 ? (this.isShow = true) : (this.isShow = false);
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
  // height:calc(100vh - 91px);
  //background:red
}
.smk-select {
  margin-bottom: 20px;
  span {
    color: $selfColor;
  }
}

.img_wrap {
  width: 190px;
  height: 340px;
  // height: 35vh;
  position: relative;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 15px;
}
.img_wrap img {
  width: 100%;
  height: 100%;
}
.img_wrap img[lazy="loading"] {
  height: 10px;
  width: 10px;
}
.img_wrap:hover .title_hint {
  display: block;
}
.title_hint {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  line-height: 340px;
}
div /deep/ .el-dialog {
  margin: 0 auto;
}
//canvas
div /deep/ .el-dialog__body {
  padding: 30px 50px;
}
.pagination {
  width: 50%;
  margin: 20px auto 0;
}
.kong {
  text-align: center;
  font-size: 14px;
  color: #aaaaaa;
}
</style>
